<template>
  <v-main
    id="pages"
    :class="$vuetify.theme.dark ? undefined : 'grey lighten-3'"
  >
    <v-img
      :class="{
        'v-image--sm': this.$vuetify.breakpoint.smAndDown,
        'v-image--md': this.$vuetify.breakpoint.mdAndDown
      }"
      :src="require(`@/assets/background/${src || '404.png'}`)"
      aspect-ratio="16/9"
    >
      <v-responsive
        :style="styles"
        min-height="100vh"
        class="d-flex align-center"
      >
        <router-view />
      </v-responsive>
    </v-img>
  </v-main>
</template>

<script>
  export default {
    name: 'PagesCoreView',

    data: () => ({
      srcs: {
        '/pages/login': 'background.jpg',
        '/pages/register': 'background.jpg',
      },
    }),

    computed: {
      src () {
        return this.srcs[this.$route.path]
      },
      styles () {
        const tmp = this.$route.path === '/pages/login' ? 125 : 75
        // console.log('tmp', tmp)
        const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 125 : 100
        const paddingBottom = this.$vuetify.breakpoint.mdAndUp ? tmp : 150
        return {
          padding: `${paddingTop}px 0 ${paddingBottom}px 0`,
        }
      },
    },
  }
</script>
